import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { DeviceDetailsService } from './device-details.service';

export const deviceDetailsResolver: ResolveFn<boolean> = (route) => {
	const deviceDetailsService = inject(DeviceDetailsService);

	return deviceDetailsService.getDevice(route.paramMap.get('deviceName') as string);
};
