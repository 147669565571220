import {
	DeviceAndroidPolicy,
	DeviceRestrictionUpdate,
} from '@root/app/feature/devices/devices.types';

import { GroupRestrictionsUpdate } from '../../policy-details.types';

export const mapRestrictionsUpdate = (data: DeviceAndroidPolicy[]): DeviceRestrictionUpdate[] => {
	return data.map(
		(el): GroupRestrictionsUpdate => ({
			settingId: el.settingId,
			setting: {
				androidValue: el.setting.androidValue as string,
			},
			value: el.value,
		}),
	);
};
