<div
	class="customer-selection w-full bg-layout-sky px-4 py-2 border border-layout-lavender-gray rounded flex justify-between items-center"
>
	<csd-app-user-image
		classList="ml-4 mr-4 w-10 h-10"
		[image]="userImg()"
		[user]="userName()"
	></csd-app-user-image>
	<div class="user-info grow w-full overflow-hidden">
		<p class="text-base text-layout-stone-gray font-bold truncate">{{ userName() }}</p>
		<p class="text-xs text-layout-quicksilver truncate">{{ customer()?.streetAddress || '' }}</p>
	</div>
	<div class="search-area">
		<p class="m-0 pl-5 flex items-center justify-center">
			<button class="text-3xl leading-7" (click)="removeSelection()">
				<mat-icon inline matRipple class="cancel-icon rounded-full">cancel</mat-icon>
			</button>
		</p>
	</div>
</div>
