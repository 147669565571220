import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	effect,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { MatOptionModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { CustomersService } from '@feature/customers/customers.service';
import { ButtonComponent } from '@ui/button/button.component';
import { HeaderDividerComponent } from '@ui/header-divider/header-divider.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';
import { catchError, Subscription, tap } from 'rxjs';

import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DevicesService } from '../../devices/devices.service';
import { Group } from '../../policy-details/policy-details.types';
import { ANDROID_COMMANDS, COMMAND_BUTTONS, UPDATES_CHANNELS } from './stats.constants';

@Component({
	selector: 'csd-app-stats',
	standalone: true,
	imports: [
		ButtonComponent,
		DatePipe,
		HeaderDividerComponent,
		LoaderComponent,
		MatIconModule,
		MatOptionModule,
		MatProgressSpinnerModule,
		MatRippleModule,
		MatSelectModule,
		ReactiveFormsModule,
	],
	templateUrl: './stats.component.html',
	styleUrl: './stats.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StatsComponent implements OnInit, OnDestroy {
	private changeDetectorRef = inject(ChangeDetectorRef);
	private deviceDetailsService = inject(DeviceDetailsService);
	private devicesService = inject(DevicesService);
	private fb = inject(FormBuilder);
	private customersService = inject(CustomersService);
	private notificationService = inject(NotificationService);

	device = this.deviceDetailsService.device;
	selectedWorkOrder = this.devicesService.selectedWorkOrder;

	commandButtons = COMMAND_BUTTONS;
	updatesChannels = UPDATES_CHANNELS;
	form = this.fb.group({
		name: this.device().name,
		releaseChannel: this.device().releaseChannel,
		group: this.device().group.id || '',
	});

	groups = signal<Group[]>([]);
	isEditingName = signal(false);
	isPasswordVisible = signal(false);
	loadingCmdUpdates = signal(false);

	subscriptions: Subscription[] = [];

	constructor() {
		effect(() => {
			if (this.device().id) {
				this.changeDetectorRef.detectChanges();
			}
		});
	}

	get baseDataToUpdate() {
		return {
			name: this.device().name,
			group: this.device().groupId || (this.device().group['id'] as string),
			uninstallKey: this.device().uninstallKey,
			releaseChannel: this.device().releaseChannel,
			workOrder: this.device().workOrder || this.selectedWorkOrder(),
		};
	}

	ngOnInit(): void {
		const customerSub$ = this.deviceDetailsService
			.getGroupsByCustomer(this.customersService.selectedCustomerId() as string)
			.pipe(
				catchError(() => {
					this.form.get('group')?.disable();
					return [];
				}),
			)
			.subscribe({
				next: (groups) => {
					this.groups.set(groups);

					if (groups && groups.length === 0) {
						this.form.get('group')?.disable();
					}
				},
				error: () => {
					this.form.get('group')?.disable();
				},
			});

		this.subscriptions.push(customerSub$);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	commandDevice(command: string) {
		const commandNumber = ANDROID_COMMANDS[command];
		const commandLabel = this.commandButtons.find((c) => c.command === command)?.label;
		const deviceId = this.device().id;

		this.loadingCmdUpdates.set(true);

		const commandSub$ = this.deviceDetailsService
			.sendCommand(deviceId, commandNumber)
			.pipe(
				tap(() => this.loadingCmdUpdates.set(false)),
				catchError(() => {
					this.loadingCmdUpdates.set(false);
					throw new Error(`Error updating device with command: ${commandLabel}`);
				}),
			)
			.subscribe(() => {
				this.openSuccessNotification(
					`Successfully applied command <strong>${commandLabel}</strong> to the device`,
				);
			});

		this.subscriptions.push(commandSub$);
	}

	openEditName() {
		this.isEditingName.set(true);
	}

	openSuccessNotification(message: string) {
		this.notificationService.open({
			variation: NotificationVariation.SUCCESS,
			message,
			duration: 5000,
		});
	}

	sendCommand(command: string) {
		const deviceId = this.device().id;

		if (!deviceId) {
			throw new Error('No device id found');
		}

		this.commandDevice(command);
	}

	saveName() {
		const name = this.form.get('name')?.value;

		if (!name) {
			return;
		}

		const data = {
			...this.baseDataToUpdate,
			name: this.form.get('name')?.value as string,
		};

		const updateSub$ = this.deviceDetailsService
			.updateDevice(this.device().id, data)
			.subscribe(() => {
				this.openSuccessNotification('Device name was updated.');
				this.isEditingName.set(false);

				const deviceSub$ = this.deviceDetailsService.getDevice(this.device().id).subscribe();
				this.subscriptions.push(deviceSub$);
			});

		this.subscriptions.push(updateSub$);
	}

	saveReleaseChange() {
		const releaseChannel = this.form.get('releaseChannel')?.value;

		const data = {
			...this.baseDataToUpdate,
			releaseChannel: parseInt(`${releaseChannel}` || '0'),
		};

		const updateSub$ = this.deviceDetailsService
			.updateDevice(this.device().id, data)
			.subscribe(() => {
				this.openSuccessNotification('Release channel updated.');
			});

		this.subscriptions.push(updateSub$);
	}

	saveGroupChange() {
		const groupId = this.form.get('group')?.value as string;

		const data = {
			...this.baseDataToUpdate,
			group: groupId,
		};

		const groupName = this.groups().find((g) => g.id === groupId)?.name || '';

		const updateSub$ = this.deviceDetailsService
			.updateDevice(this.device().id, data)
			.subscribe(() => {
				this.openSuccessNotification('Group updated to ' + groupName);
			});

		this.subscriptions.push(updateSub$);
	}

	selectCompareValue(first: string | number, second: string | number) {
		if (Number.isNaN(+first) || Number.isNaN(+second)) {
			return first === second;
		}

		return +first === +second;
	}

	togglePassword() {
		this.isPasswordVisible.set(!this.isPasswordVisible());
	}
}
