<div class="app-item bg-layout-sky rounded flex justify-between items-center pr-3">
	<mat-checkbox class="app-item__checkbox" [checked]="checked()" (change)="update($event.checked)">
		{{ text() }}
	</mat-checkbox>
	@if (description()) {
		<mat-icon [matTooltip]="description()" class="material-symbols-filled text-layout-shingle-gray"
			>info</mat-icon
		>
	}
</div>
