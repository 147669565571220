import { Component, computed, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { CustomersService } from '@feature/customers/customers.service';
import { ButtonComponent } from '@ui/button/button.component';

import { UserAreaComponent } from '../user-area/user-area.component';
import { CONFIG_BUTTONS, CUSTOMER_BUTTONS } from './constants';

@Component({
	selector: 'csd-app-sidebar',
	standalone: true,
	templateUrl: './sidebar.component.html',
	styleUrl: './sidebar.component.scss',
	imports: [ButtonComponent, RouterLink, UserAreaComponent],
})
export class SidebarComponent {
	private router = inject(Router);
	private customersService = inject(CustomersService);

	customer = this.customersService.customer;
	customerButtons = CUSTOMER_BUTTONS;
	configButtons = computed(() => {
		return CONFIG_BUTTONS.map((buttonData) => ({
			...buttonData,
			path: buttonData.path.replace('{customer}/', `${this.customer()?.code || 'public'}/`),
		}));
	});

	getPath(route: string) {
		return [this.customer()?.code, route];
	}

	validateActiveRoute(route: string): boolean {
		const routeRegex = new RegExp(`${route}`, 'gi');

		return !!this.router.url.match(routeRegex);
	}
}
