import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { catchError } from 'rxjs';

import { PolicyDetailsService } from './policy-details.service';
import { Group } from './policy-details.types';

export const policyDetailsResolver: ResolveFn<Group> = (route) => {
	const policyDetailsService = inject(PolicyDetailsService);

	return policyDetailsService.getGroup(route.paramMap.get('groupId') as string).pipe(
		catchError(() => {
			throw new Error('Error getting policy details. Verify the group ID and try again.');
		}),
	);
};
