<div class="device-restrictions">
	@for (item of device().policy.policyAndroidSettings; track item.settingId; let i = $index) {
		<csd-app-app-checkbox
			class="w-full"
			[text]="item.setting.name"
			[checked]="item.value"
			[description]="getDescription(item.differentThanGroup)"
			(change)="changeSelection($event, $index)"
		></csd-app-app-checkbox>
	}
</div>
