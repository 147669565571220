import { computed, inject, Injectable, signal } from '@angular/core';
import { toObservable, toSignal } from '@angular/core/rxjs-interop';
import { ApiService } from '@core/api/api.service';
import { catchError, debounceTime, filter, map, switchMap, tap } from 'rxjs';

import { Customer, CustomersListResponse } from './customer.types';

@Injectable({
	providedIn: 'root',
})
export class CustomersService {
	private apiService = inject(ApiService);

	selectedCustomerId = signal<string | undefined>(undefined);
	isLoading = signal<boolean>(false);

	private customer$ = toObservable(this.selectedCustomerId).pipe(
		tap(() => {
			this.isLoading.set(!!this.selectedCustomerId());
			this.computedCustomer().set(undefined);
		}),
		filter((customerId) => !!customerId),
		filter((customerId) => !!customerId?.replace(/\//g, '')),
		debounceTime(200),
		switchMap((customerId) => {
			return this.apiService.get<Customer>(`/customer/${customerId}`);
		}),
		tap(() => this.isLoading.set(false)),
	);

	private readonlyCustomer = toSignal<Customer>(this.customer$);
	private computedCustomer = computed(() => signal(this.readonlyCustomer()));
	customer = computed(() => this.computedCustomer()());

	getAllCustomers() {
		this.isLoading.set(true);

		return toSignal(
			this.apiService.get<CustomersListResponse>('/customer?page=1&numberPerPage=50').pipe(
				tap(() => this.isLoading.set(false)),
				map((data) => data.customers),
				catchError(() => {
					this.isLoading.set(false);
					throw new Error('Error getting customers list');
				}),
			),
		);
	}

	getCustomerSelected() {
		return this.selectedCustomerId();
	}

	updateCustomer(customerCode: string, customer: Record<string, unknown>) {
		return this.apiService.put(`/customer/${customerCode}`, customer).pipe(
			catchError(() => {
				throw new Error('Error updating customer notification preferences');
			}),
		);
	}

	setCustomerSelected(customer: string) {
		this.selectedCustomerId.set(customer);
	}
}
