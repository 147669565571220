import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { RouterLink } from '@angular/router';
import { triggerOpenClose } from '@root/app/animations';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { catchError, tap } from 'rxjs';

import { GroupGeneralUpdate } from '../policy-details.types';
import { PolicyDetailsService } from './../policy-details.service';

@Component({
	selector: 'csd-app-general',
	standalone: true,
	imports: [MatInputModule, ReactiveFormsModule, ButtonComponent, RouterLink, LoadingIconComponent],
	templateUrl: './general.component.html',
	styleUrl: './general.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [triggerOpenClose],
})
export class GeneralComponent {
	fb = inject(FormBuilder);
	notificationService = inject(NotificationService);
	policyDetailsService = inject(PolicyDetailsService);

	currentGroup = this.policyDetailsService.currentGroup;
	isLoading = signal<boolean>(false);
	form = this.fb.group({
		name: [
			this.currentGroup()?.name || '',
			[Validators.required, Validators.pattern('[A-Za-z0-9 \'"-]*')],
		],
		churchCode: this.currentGroup()?.churchCode || '',
		customUser: this.currentGroup()?.customUser || '',
	});

	get hasNameError() {
		return this.form.dirty && this.form.get('name')?.invalid;
	}

	onSave(event: SubmitEvent) {
		this.form.markAsDirty();
		event.preventDefault();

		if (this.form.invalid) {
			return;
		}

		const data = {
			...this.form.value,
			policyId: this.currentGroup().policyId,
		} as GroupGeneralUpdate;

		this.isLoading.set(true);
		this.policyDetailsService
			.simpleUpdateGroup(this.currentGroup().id, data)
			.pipe(
				tap(() => this.isLoading.set(false)),
				catchError(() => {
					this.isLoading.set(false);
					throw new Error('Error on group update. Please try again.');
				}),
			)
			.subscribe(this.successfulUpdate.bind(this));
	}

	successfulUpdate() {
		this.policyDetailsService.getGroup(this.currentGroup().id).subscribe();
		this.notificationService.openSuccess({
			message: `Group <strong>${this.currentGroup()?.name}</strong> was updated`,
		});
	}
}
