import { Injectable, signal } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Subscription } from 'rxjs';

export interface AuthUserData {
	email: string;
	email_verified: boolean;
	given_name: string;
	groups: string[];
	name: string;
	nickname: string;
	nonce: string;
	preferred_username: string;
	sub: string;
}

@Injectable({
	providedIn: 'root',
})
export class AuthService {
	public userData = signal<AuthUserData>({} as AuthUserData);

	subscription$?: Subscription;

	constructor(private oidcSecurityService: OidcSecurityService) {
		this.subscription$ = this.oidcSecurityService.userData$.subscribe((response) => {
			this.userData.set(response.userData as AuthUserData);
		});
	}

	isAutheticated$() {
		return this.oidcSecurityService.isAuthenticated$;
	}

	login() {
		this.oidcSecurityService.authorize();
	}

	logout() {
		this.subscription$?.unsubscribe();
		return this.oidcSecurityService.logoff();
	}

	checkAuth() {
		return this.oidcSecurityService.checkAuth();
	}
}
