import { ChangeDetectionStrategy, Component, inject, OnDestroy, signal } from '@angular/core';
import { AppCheckboxComponent } from '@ui/app-checkbox/app-checkbox.component';
import { NotificationService } from '@ui/notification/notification.service';
import { Subscription } from 'rxjs';

import { DeviceDetailsService } from '../../device-details/device-details.service';
import { DeviceAndroidPolicy, DeviceRestrictionUpdate } from '../../devices/devices.types';

@Component({
	selector: 'csd-app-restrictions',
	standalone: true,
	imports: [AppCheckboxComponent],
	templateUrl: './restrictions.component.html',
	styleUrl: './restrictions.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RestrictionsComponent implements OnDestroy {
	#deviceDetailsService = inject(DeviceDetailsService);
	#notificationService = inject(NotificationService);

	device = this.#deviceDetailsService.device;
	currentPolicy = signal<DeviceAndroidPolicy>({} as DeviceAndroidPolicy);
	subscriptions: Subscription[] = [];

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	changeSelection(value: boolean, index: number) {
		const policyAndroidSettings = [...this.device().policy.policyAndroidSettings];
		const policyToUpdate = { ...policyAndroidSettings[index] };

		policyToUpdate.value = value;
		this.currentPolicy.set(policyToUpdate);

		this.updateDevice({
			settingId: policyToUpdate.settingId,
			setting: {
				androidValue: policyToUpdate.setting?.androidValue as string,
			},
			value: policyToUpdate.value,
		});
	}

	getDescription(differentThanGroup: boolean) {
		return differentThanGroup ? 'This policy differs from the group policy' : '';
	}

	updateDevice(data: DeviceRestrictionUpdate) {
		const update$ = this.#deviceDetailsService.updateDeviceRestriction(this.device().id, data);

		const sub$ = update$.subscribe({
			next: this.updateSuccess.bind(this),
			error: this.updateError.bind(this),
		});

		this.subscriptions.push(sub$);
	}

	updateError() {
		const settingName = this.currentPolicy().setting.name;

		this.#notificationService.openError({
			message: `
        There was an error saving your changes for <strong>${settingName}</strong>.
        Please try updating the restriction again.
      `,
		});
	}

	updateSuccess() {
		const settingName = this.currentPolicy().setting.name;
		const settingValue = this.currentPolicy().value;
		const settingStatus = settingValue ? 'restricted' : 'allowed';

		this.#notificationService.openSuccess({
			message: `<strong>${settingName}</strong> is now ${settingStatus}.`,
		});
	}
}
