import {
	ChangeDetectionStrategy,
	Component,
	effect,
	inject,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { Router, RouterLink } from '@angular/router';
import { CustomersService } from '@feature/customers/customers.service';
import { ButtonComponent } from '@ui/button/button.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';
import { SectionComponent } from '@ui/section/section.component';
import { catchError, Subscription } from 'rxjs';

import { EmptyStateComponent } from '../../ui/empty-state/empty-state.component';
import { ModalService } from './../../ui/modal/modal.service';
import { PolicyDetailsService } from './../policy-details/policy-details.service';
import { PoliciesService } from './policies.service';
import { GeneralPolicy } from './policies.types';
import { SortableListComponent } from './sortable-list/sortable-list.component';
import { SortableItem, SortableList } from './sortable-list/sortable-list.types';

@Component({
	selector: 'csd-app-policies',
	standalone: true,
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		LoaderComponent,
		MatIconModule,
		RouterLink,
		SectionComponent,
		SortableListComponent,
	],
	templateUrl: './policies.component.html',
	styleUrl: './policies.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PoliciesComponent implements OnInit, OnDestroy {
	customersService = inject(CustomersService);
	modalService = inject(ModalService);
	notificationService = inject(NotificationService);
	policiesService = inject(PoliciesService);
	policyDetailsService = inject(PolicyDetailsService);
	router = inject(Router);

	customer = this.customersService.customer;
	isLoading = this.policiesService.isLoading;
	isLoadingDetails = this.policyDetailsService.isLoadingDetails;
	subscriptions: Subscription[] = [];

	customerPolicies = signal<SortableList>([]);
	otherPolicies = signal<SortableList>([]);

	constructor() {
		effect(
			() => {
				if (this.customer()?.code) {
					this.loadCustomerPolicies();
				}
			},
			{
				allowSignalWrites: true,
			},
		);
	}

	ngOnInit(): void {
		const customerCode = this.customer()?.code;
		const isPublicRoute = this.router.url.split('/').filter((el) => el)[0] === 'public';

		if (customerCode && !isPublicRoute) {
			this.router.navigate([`${customerCode}/policies/`], { replaceUrl: true });
			return;
		}

		if (isPublicRoute) {
			this.loadPublicPolicies();
		}
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	async deletePolicy(item: SortableItem) {
		await this.modalService.open('delete-policy', { policy: item, customer: this.customer() });
		this.modalService.afterClose(this.handleCloseModal.bind(this));
	}

	handleCloseModal(data: unknown) {
		this.modalService.close();

		if (!data) {
			return;
		}

		this.loadCustomerPolicies();
	}

	loadCustomerPolicies() {
		const sub$ = this.policiesService
			.getByCustomer(this.customer()?.code ?? '', this.customer()?.id ?? '')
			.pipe(
				catchError(() => {
					this.notificationService.open({
						variation: NotificationVariation.ERROR,
						message: 'An error occurred while trying to load policies. Please try again later.',
					});

					return [];
				}),
			)
			.subscribe((data) => {
				const { ownerPolicies, allPolicies } = data;
				this.setPoliciesData(ownerPolicies, allPolicies);
			});

		this.subscriptions.push(sub$);
	}

	loadPublicPolicies() {
		const sub$ = this.policiesService.getPublicPolicies().subscribe((data) => {
			const { allPolicies } = data;
			this.setPoliciesData([], allPolicies);
		});

		this.subscriptions.push(sub$);
	}

	setPoliciesData(ownerPolicies: GeneralPolicy[], otherPolicies: GeneralPolicy[]) {
		const ownerDataWithLink = ownerPolicies.map((item) => ({
			...item,
			link: `./${item.id}`,
		}));
		const otherDataWithLink = otherPolicies.map((item) => ({
			...item,
			link: `./${item.id}`,
		}));

		this.customerPolicies.set(ownerDataWithLink);
		this.otherPolicies.set(otherDataWithLink);
	}
}
