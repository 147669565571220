import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { DevicesService } from '../devices.service';

export const addDeviceGuard: CanActivateFn = (route, state) => {
	const devicesService = inject(DevicesService);
	const router = inject(Router);

	if (devicesService.selectedWorkOrder()) {
		return true;
	}

	const parentUrl = state.url.split('/').slice(0, -1).join('/');

	return router.createUrlTree([parentUrl]);
};
