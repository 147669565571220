export const DEVICES_LINKS = [
	{
		label: 'Stats',
		icon: 'phone_iphone',
		route: 'stats',
	},
	{
		label: 'Internet',
		icon: 'language',
		route: 'internet',
	},
	{
		label: 'Apps',
		icon: 'apps',
		route: 'apps',
	},
	{
		label: 'Restrictions',
		icon: 'phonelink_setup',
		route: 'restrictions',
	},
];

export const ROUTES_WITH_TIMERS = ['apps', 'restrictions'];
