<form class="general-form" [formGroup]="form">
	<div class="general-form__fields">
		<div class="form-element">
			<label for="name">Policy Name</label>
			<input matInput id="name" formControlName="name" placeholder="Name" />
			@if (hasNameError) {
				<div @openClose class="text-error-tertiary px-1">
					@if (form.controls['name'].errors?.['required']) {
						Please enter a policy name.
					}
					@if (form.controls['name'].errors?.['pattern']) {
						Only letters, numbers, space and special characters (- or ') are allowed.
					}
				</div>
			}
		</div>
		<div class="form-element">
			<label for="group">Church Group</label>
			<input
				matInput
				id="group"
				formControlName="churchCode"
				placeholder="If a church admin, would be assumed"
			/>
		</div>
		<div class="form-element">
			<label for="user">Custom User</label>
			<input
				matInput
				id="user"
				formControlName="customUser"
				placeholder="Customer GUID or ABC Code"
			/>
		</div>
	</div>
</form>
