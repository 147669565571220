import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	ComponentRef,
	inject,
	OnDestroy,
	OnInit,
	signal,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { DevicesService } from '@feature/devices/devices.service';
import { PoliciesService } from '@feature/policies/policies.service';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService } from '@ui/notification/notification.service';
import { SectionMenuComponent } from '@ui/section-menu/section-menu.component';
import { catchError, finalize, retry, Subscription } from 'rxjs';

import { DeviceApp } from '../../device-details/apps/apps.types';
import { DeviceAndroidPolicy } from '../../devices/devices.types';
import { DISPLAY_COMPONENTS, SECTION_FIELDS, SECTIONS } from './add-policy.constants';
import { AddPolicyDynamicComponent } from './add-policy.types';
import { AddPolicyForm, formatFormData } from './utils/format-form-data';

@Component({
	selector: 'csd-app-add-policy',
	standalone: true,
	imports: [
		ButtonComponent,
		ReactiveFormsModule,
		SectionMenuComponent,
		RouterLink,
		LoadingIconComponent,
	],
	templateUrl: './add-policy.component.html',
	styleUrl: './add-policy.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddPolicyComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('dynamicComponentContainer', { read: ViewContainerRef })
	dynamicComponentContainer!: ViewContainerRef;

	activatedRoute = inject(ActivatedRoute);
	fb = inject(FormBuilder);
	devicesService = inject(DevicesService);
	notificationService = inject(NotificationService);
	policiesService = inject(PoliciesService);
	router = inject(Router);

	componentRef!: ComponentRef<AddPolicyDynamicComponent>;
	form = this.fb.group({
		name: ['', [Validators.required, Validators.pattern('[A-Za-z0-9 \'"-]*')]],
		churchCode: '',
		customUser: '',
		apps: [[] as DeviceApp[]],
		restrictions: [[] as DeviceAndroidPolicy[]],
	});
	isLoading = signal<boolean>(false);
	selectedComponent = signal<string>('general');
	subscriptions: Subscription[] = [];

	get isFirstSection() {
		return SECTIONS.indexOf(this.selectedComponent()) === 0;
	}

	get isLastSection() {
		return SECTIONS.indexOf(this.selectedComponent()) === SECTIONS.length - 1;
	}

	ngOnInit(): void {
		const settings$ = this.devicesService.getSettingsOptions();

		settings$.pipe(
			retry(2),
			catchError(() => {
				throw new Error('Error trying to get policy settings for restrictions, please try again.');
			}),
		);

		const sub$ = settings$.subscribe((data) => {
			this.form.get('restrictions')?.setValue(data);
		});

		this.subscriptions.push(sub$);
	}

	ngAfterViewInit(): void {
		this.componentRef = this.dynamicComponentContainer.createComponent(
			DISPLAY_COMPONENTS[this.selectedComponent()],
		);
	}

	ngOnDestroy(): void {
		this.componentRef?.destroy();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	displayComponent(name: string) {
		if (name === this.selectedComponent()) {
			return;
		}

		if (this.componentRef) {
			this.componentRef.destroy();
		}

		this.selectedComponent.set(name);
		this.componentRef = this.dynamicComponentContainer.createComponent(DISPLAY_COMPONENTS[name]);
	}

	getControl(name: string) {
		return this.form.get(name);
	}

	isValidSection(section: string) {
		return SECTION_FIELDS[section].some((field) => this.getControl(field)?.invalid);
	}

	onBack(section: string) {
		this.form.markAsDirty();

		const prevSection = SECTIONS[SECTIONS.indexOf(section) - 1 || 0];
		this.displayComponent(prevSection);
	}

	onNext(section: string) {
		this.form.markAsDirty();

		if (this.isValidSection(section)) {
			return;
		}

		if (this.isLastSection) {
			this.onSave();
			return;
		}

		const nextSection = SECTIONS[SECTIONS.indexOf(section) + 1 || 0];
		this.displayComponent(nextSection);
	}

	onSave() {
		this.isLoading.set(true);

		const formData = formatFormData(this.form.value as AddPolicyForm);
		const addPolicy$ = this.policiesService.addPolicy(formData);

		addPolicy$.pipe(
			finalize(() => this.isLoading.set(false)),
			catchError(() => {
				throw new Error('Error creatting the policy. Check the form and try again.');
			}),
		);

		const sub$ = addPolicy$.subscribe((newPolicyId: string) => {
			this.notificationService.openSuccess({
				message: `Policy with name <strong>${formData.name}</strong> was created successfully.`,
			});

			this.router.navigate([`../${newPolicyId}`], {
				relativeTo: this.activatedRoute,
			});
		});

		this.subscriptions.push(sub$);
	}
}
