import { Component, inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';

import { ActivityService } from './core/activity/activity.service';
import { AuthService } from './core/auth/auth.service';
import { CustomersService } from './feature/customers/customers.service';

@Component({
	selector: 'csd-app-root',
	standalone: true,
	imports: [RouterOutlet],
	template: `<router-outlet></router-outlet>`,
})
export class AppComponent implements OnInit, OnDestroy {
	#activityService = inject(ActivityService);
	#authService = inject(AuthService);
	#customersService = inject(CustomersService);
	#matIconReg = inject(MatIconRegistry);
	#router = inject(Router);

	title = 'Control Stack Dashboard';
	subscriptions: Subscription[] = [];

	ngOnInit(): void {
		const authSub$ = this.#authService.checkAuth().subscribe();

		this.#matIconReg.setDefaultFontSetClass('material-symbols-outlined');
		this.#activityService.addListeners();

		const routerSub$ = this.#router.events.subscribe((event) => {
			if (!(event instanceof ActivationStart)) {
				return;
			}

			const customerId = event.snapshot.params['customerId'];
			const oldCustomer = this.#customersService.selectedCustomerId();
			const isSameCustomer = decodeURIComponent(customerId) && oldCustomer;
			const isPublicCustomer = customerId?.toLowerCase() === 'public';

			if (customerId && !isSameCustomer && !isPublicCustomer) {
				this.#customersService.setCustomerSelected(customerId);
			}
		});

		this.subscriptions.push(authSub$, routerSub$);
	}

	ngOnDestroy(): void {
		this.#activityService.removeListeners();
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}
}
