import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, computed, effect, inject, signal } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterOutlet } from '@angular/router';
import { CustomersService } from '@feature/customers/customers.service';
import { BreadcrumbComponent } from '@ui/breadcrumb/breadcrumb.component';
import { LoaderComponent } from '@ui/loader/loader.component';

import { CustomerSelectionComponent } from './customer-selection/customer-selection.component';
import { SearchAreaComponent } from './search-area/search-area.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { UsersLogComponent } from './users-log/users-log.component';

@Component({
	selector: 'csd-app-main-layout',
	standalone: true,
	templateUrl: './main-layout.component.html',
	styleUrl: './main-layout.component.scss',
	imports: [
		BreadcrumbComponent,
		CustomerSelectionComponent,
		MatIconModule,
		MatRippleModule,
		MatSidenavModule,
		RouterOutlet,
		SidebarComponent,
		UsersLogComponent,
		SearchAreaComponent,
		LoaderComponent,
	],
})
export class MainLayoutComponent {
	customersService = inject(CustomersService);
	breakpointObserver = inject(BreakpointObserver);

	customer = this.customersService.customer;
	searchTouched = signal<boolean>(false);
	shouldOpenSearch = signal<boolean>(true);
	shouldOpenSidenav = signal<boolean>(true);
	isLoadingCustomer = this.customersService.isLoading;
	isMobile = signal<boolean>(false);
	isSearchOpen = computed(() => {
		const isCustomerActive = !!this.customer()?.code;
		const searchOpen = this.shouldOpenSearch();

		if (!isCustomerActive) {
			return true;
		}

		if (!this.searchTouched()) {
			return false;
		}

		return !isCustomerActive || searchOpen;
	});

	constructor() {
		effect(
			() => {
				if (this.customer()?.code) {
					this.shouldOpenSearch.set(false);
				}
			},
			{ allowSignalWrites: true },
		);

		this.breakpointObserver.observe(['(max-width: 1024px)']).subscribe((screenSize) => {
			this.shouldOpenSidenav.set(!screenSize.matches);
			this.isMobile.set(screenSize.matches);
		});
	}

	toggleSearch() {
		const value = !this.customer() ? true : !this.shouldOpenSearch();

		this.searchTouched.set(true);
		this.shouldOpenSearch.set(value);
	}

	openSideNav() {
		this.shouldOpenSidenav.set(true);
	}

	onChangeSidenav(isOpen: boolean) {
		this.shouldOpenSidenav.set(isOpen);
	}

	searchFocusOut(searchValue: string) {
		if (!searchValue) {
			this.shouldOpenSearch.set(false);
		}
	}
}
