import { DeviceAndroidPolicy } from '@feature/devices/devices.types';
import { DeviceNewAndroidPolicy } from '@feature/policy-details/policy-details.types';

export const formatAndroidSettings = (settings: DeviceAndroidPolicy[]) => {
	return settings
		.filter((restriction) => restriction.value)
		.map(
			(restriction): DeviceNewAndroidPolicy => ({
				settingId: restriction.settingId,
				value: restriction.value,
			}),
		);
};
