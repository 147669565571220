import { DeviceAppPolicy } from '@feature/device-details/apps/apps.types';
import { DeviceNewAppPolicy } from '@feature/policy-details/policy-details.types';

export const formatAndroidApps = (data: DeviceAppPolicy[]) =>
	data.map(
		(app): DeviceNewAppPolicy => ({
			appId: app.appResponse.id,
			packageName: app.appResponse.packageName,
			status: Number(app.status),
			internetConfig: Number(app.internetConfig),
		}),
	);
