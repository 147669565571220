import { DatePipe } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	computed,
	inject,
	input,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import {
	ActivatedRoute,
	Event,
	NavigationEnd,
	Router,
	RouterLink,
	RouterLinkActive,
	RouterOutlet,
	Scroll,
} from '@angular/router';
import { CustomersService } from '@feature/customers/customers.service';
import { DeviceDetailsService } from '@feature/device-details/device-details.service';
import { BannerComponent } from '@ui/banner/banner.component';
import { ButtonComponent } from '@ui/button/button.component';
import { LoadingIconComponent } from '@ui/loading-icon/loading-icon.component';
import { NotificationService, NotificationVariation } from '@ui/notification/notification.service';
import { SectionMenuComponent, SectionMenuItem } from '@ui/section-menu/section-menu.component';
import { catchError, filter, Subscription, tap } from 'rxjs';

import { DEVICES_LINKS, ROUTES_WITH_TIMERS } from './device-layout.constants';

@Component({
	selector: 'csd-app-device-layout',
	standalone: true,
	imports: [
		BannerComponent,
		ButtonComponent,
		DatePipe,
		LoadingIconComponent,
		MatIconModule,
		RouterLink,
		RouterLinkActive,
		RouterOutlet,
		SectionMenuComponent,
	],
	templateUrl: './device-layout.component.html',
	styleUrl: './device-layout.component.scss',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeviceLayoutComponent implements OnInit, OnDestroy {
	activatedRoute = inject(ActivatedRoute);
	customersService = inject(CustomersService);
	deviceDetailsService = inject(DeviceDetailsService);
	notificationService = inject(NotificationService);
	router = inject(Router);

	deviceName = input<string>('');
	device = this.deviceDetailsService.device;
	isRemovingTimer = signal<boolean>(false);
	shouldDisplayBanner = signal<boolean>(false);
	currentSection = signal<string>('');
	subscriptions: Subscription[] = [];

	devicesLinks = computed<SectionMenuItem[]>(() =>
		DEVICES_LINKS.map((el) => {
			const { overrideFinishedUtc } = this.deviceDetailsService.device();
			const customerCode = this.customersService.customer()?.code ?? '';
			const route = `/${customerCode}/devices/${this.deviceName()}/${el.route}`;

			return {
				...el,
				route,
				active: this.currentSection() === el.route,
				timer: !!(ROUTES_WITH_TIMERS.includes(el.route) && overrideFinishedUtc),
			};
		}),
	);

	ngOnInit(): void {
		const routeSub$ = this.router.events
			.pipe(filter(this.isNavigationEndEvent.bind(this)))
			.subscribe(() => {
				const urlLastSection = this.router.url.split('/').pop();

				this.currentSection.set(urlLastSection?.replace(/(\?|\#).*/gi, '') as string);
				this.updateDisplayBanner();
			});

		this.subscriptions.push(routeSub$);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	closeDevice() {
		this.isRemovingTimer.set(true);
		const closeSub$ = this.deviceDetailsService
			.removeTimer(this.device().id)
			.pipe(
				tap(() => this.isRemovingTimer.set(false)),
				catchError(() => {
					this.isRemovingTimer.set(false);
					throw new Error(
						`<p>Unable to remove temporary timer.</p>
            <p>Please check your device settings and try again.</p>`,
					);
				}),
			)
			.subscribe(this.timerRemoved.bind(this));

		this.subscriptions.push(closeSub$);
	}

	isNavigationEndEvent(event: Event) {
		const isNavigationEnd = event instanceof NavigationEnd;
		const isScroll = event instanceof Scroll;

		return isNavigationEnd || (isScroll && event.routerEvent instanceof NavigationEnd);
	}

	updateDisplayBanner() {
		const { overrideFinishedUtc } = this.deviceDetailsService.device();
		const currentPath = this.activatedRoute.snapshot.firstChild?.url[0].path;
		const isTimerRoute = ROUTES_WITH_TIMERS.includes(currentPath || '');
		const shouldDisplayBanner = isTimerRoute && !!overrideFinishedUtc;

		this.shouldDisplayBanner.set(shouldDisplayBanner);
	}

	timerRemoved() {
		this.notificationService.open({
			variation: NotificationVariation.SUCCESS,
			message: `Removed temporary timer for <strong>${this.device().name}</strong>.`,
		});

		this.deviceDetailsService.getDevice(this.device().id);
	}
}
