import { DeviceAppPolicy } from '@feature/device-details/apps/apps.types';
import { DeviceAndroidPolicy } from '@feature/devices/devices.types';
import { GroupNew } from '@feature/policy-details/policy-details.types';

import { formatAndroidApps } from './format-android-apps';
import { formatAndroidSettings } from './format-android-settings';

export interface AddPolicyForm {
	name?: string;
	churchCode?: string;
	customUser?: string;
	restrictions?: DeviceAndroidPolicy[];
	apps?: DeviceAppPolicy[];
}

export const formatFormData = (data: AddPolicyForm): GroupNew => {
	return {
		name: data['name'] || '',
		churchCode: data['churchCode'] || '',
		customUser: data['customUser'] || '',
		policy: {
			policyAndroidSettings: formatAndroidSettings(data['restrictions'] || []),
			policyAppJoins: formatAndroidApps(data['apps'] || []),
		},
	};
};
