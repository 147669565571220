import { inject, Injectable, signal } from '@angular/core';
import { ApiService } from '@core/api/api.service';
import { catchError, finalize, map, tap } from 'rxjs';

import {
	Group,
	GroupAppsUpdate,
	GroupGeneralUpdate,
	GroupRestrictionsUpdate,
} from './policy-details.types';

@Injectable({
	providedIn: 'root',
})
export class PolicyDetailsService {
	apiService = inject(ApiService);

	isLoadingDetails = signal<boolean>(false);
	currentGroup = signal<Group>({} as Group);

	getGroup(groupId: string) {
		this.isLoadingDetails.set(true);

		return this.apiService.get<Group>(`/android/groups/${groupId}`).pipe(
			map((data) => {
				const policyAndroidSettings = [...data.policy.policyAndroidSettings];

				policyAndroidSettings.sort((a, b) => {
					if (a.setting.name < b.setting.name) {
						return -1;
					}

					if (a.setting.name > b.setting.name) {
						return 1;
					}

					return 0;
				});

				data.policy.policyAndroidSettings = policyAndroidSettings;

				return data;
			}),
			tap((data) => {
				this.currentGroup.set(data);
			}),
			catchError((err) => {
				throw new Error(err.error?.title || err.message);
			}),
			finalize(() => this.isLoadingDetails.set(false)),
		);
	}

	simpleUpdateGroup(groupId: string, data: GroupGeneralUpdate) {
		return this.apiService.put<void>(`/android/groups/${groupId}`, data);
	}

	updateApps(groupId: string, data: GroupAppsUpdate[], pushChanges: boolean = false) {
		return this.apiService.put<void>(`/android/groups/appstatus/${groupId}`, data, {
			params: { pushChanges },
		});
	}

	updateRestrictions(
		groupId: string,
		data: GroupRestrictionsUpdate[],
		pushChanges: boolean = false,
	) {
		return this.apiService.put<void>(`/android/groups/settingvalue/${groupId}`, data, {
			params: { pushChanges },
		});
	}
}
