import {
	AfterViewInit,
	Component,
	computed,
	inject,
	OnInit,
	signal,
	ViewChild,
} from '@angular/core';
import {
	MatExpansionModule,
	MatExpansionPanel,
	MatExpansionPanelContent,
} from '@angular/material/expansion';
import { Router } from '@angular/router';
import { ApiService } from '@core/api/api.service';
import { AuthService, AuthUserData } from '@core/auth/auth.service';
import { UserService } from '@feature/user/user.service';
import { ButtonComponent } from '@ui/button/button.component';
import { UserImageComponent } from '@ui/user-image/user-image.component';

type CompanyGroup = {
	id: number;
	user: string;
	accessName: string | null;
	groupName: string | null;
	access: string;
};

@Component({
	selector: 'csd-app-user-area',
	standalone: true,
	templateUrl: './user-area.component.html',
	styleUrl: './user-area.component.scss',
	imports: [MatExpansionModule, ButtonComponent, MatExpansionPanelContent, UserImageComponent],
})
export class UserAreaComponent implements OnInit, AfterViewInit {
	@ViewChild(MatExpansionPanel) expansionPanel!: MatExpansionPanel;

	private apiService = inject(ApiService);
	private authService = inject(AuthService);
	private router = inject(Router);
	private userService = inject(UserService);

	userImg = '';

	groupList = signal<CompanyGroup[]>([]);
	user = signal<AuthUserData | null>(null);
	userState = this.userService.user;

	userName = computed(() => this.user()?.name ?? '');
	userRole = computed(() => this.user()?.groups[0]?.split('_').join(' '));

	ngAfterViewInit(): void {
		this.expansionPanel._body.nativeElement.classList.add('user-area__content');
	}

	ngOnInit(): void {
		const userData = this.authService.userData();

		this.user.set(userData);
		this.apiService
			.get(`/access/listcontent/${userData?.sub}`)
			.subscribe(this.updateListContent.bind(this));
	}

	getGroupName(group: CompanyGroup) {
		return group.groupName || group.accessName || group.access;
	}

	isActiveGroup(groupId: string, isFirst: boolean) {
		const { selectedGroup } = this.userState();

		return selectedGroup ? selectedGroup === groupId : isFirst;
	}

	logout() {
		this.authService.logout().subscribe(() => {
			this.router.navigate(['authentication', 'login']);
		});
	}

	updateListContent(data: any) {
		if (!data || !Array.isArray(data)) {
			this.groupList.set([]);
		}

		this.groupList.set(data);
		this.userService.updateUser({ selectedGroup: data?.[0]?.access });
	}

	updateSelectedGroup(groupId: string) {
		this.userService.updateUser({ selectedGroup: groupId });
	}
}
