<div
	cdkDropList
	[cdkDropListDisabled]="disableDrop()"
	class="space-y-3"
	(cdkDropListDropped)="drop($event)"
>
	@for (rule of list(); track $index) {
		<div class="cdk-drag-box flex items-center bg-white rounded pl-3 pr-5 py-2" cdkDrag>
			@if (!disableDrop()) {
				<div class="cdk-drag-handle" cdkDragHandle>
					<mat-icon inline class="align-middle">expand_all</mat-icon>
				</div>
				<div class="cdk-drag-placeholder" *cdkDragPlaceholder></div>
			}
			<div class="content-box w-full flex items-center">
				<div class="w-full sm:w-auto flex flex-col sm:flex-row sm:grow">
					<p class="grow m-0">{{ rule['name'] }}</p>
					@if (rule['owner']) {
						<csd-app-tag [classList]="getColorVariation(rule['owner'])">
							{{ toString(rule['owner']) | uppercase }}
						</csd-app-tag>
					}
				</div>

				<div class="flex justify-end sm:justify-center min-w-fit">
					<div class="item-actions pl-2">
						<a [routerLink]="rule['link']">
							<button matRipple class="rounded px-2 py-1 text-xl leading-none">
								<mat-icon inline>edit</mat-icon>
							</button>
						</a>
						<button
							class="text-layout-scarlet-red rounded px-2 py-1 text-xl leading-none"
							(click)="onDelete(rule)"
							matRipple
						>
							<mat-icon inline>delete</mat-icon>
						</button>
					</div>
				</div>
			</div>
		</div>
	}
</div>
