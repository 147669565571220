import {
	ChangeDetectionStrategy,
	Component,
	effect,
	inject,
	OnDestroy,
	signal,
} from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { Router, RouterLink } from '@angular/router';
import { ButtonComponent } from '@ui/button/button.component';
import { EmptyStateComponent } from '@ui/empty-state/empty-state.component';
import { InlineMessageComponent } from '@ui/inline-message/inline-message.component';
import { LoaderComponent } from '@ui/loader/loader.component';
import { ModalService } from '@ui/modal/modal.service';
import { ModalTypes } from '@ui/modal/modal.types';
import { Subscription } from 'rxjs';

import { CustomersService } from '../customers/customers.service';
import { MODAL_CONFIG } from './devices.constants';
import { DevicesService } from './devices.service';
import { DeviceAndroid, DeviceRouter } from './devices.types';

@Component({
	selector: 'csd-app-devices',
	standalone: true,
	templateUrl: './devices.component.html',
	styleUrl: './devices.component.scss',
	imports: [
		ButtonComponent,
		EmptyStateComponent,
		InlineMessageComponent,
		LoaderComponent,
		MatDividerModule,
		MatIconModule,
		MatRippleModule,
		MatTableModule,
		RouterLink,
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DevicesComponent implements OnDestroy {
	customersService = inject(CustomersService);
	devicesService = inject(DevicesService);
	modalService = inject(ModalService);
	router = inject(Router);

	mdmColumns = ['os', 'deviceName', 'licenseNumber', 'timer', 'delete'];
	connectionsColumns = ['device', 'identifier', 'timer', 'delete'];
	customer = this.customersService.customer;
	isLoading = this.devicesService.isLoading;
	groupSubscriptions = this.devicesService.devices;
	areDevicesLoaded = signal<boolean>(false);
	subscriptions: Subscription[] = [];

	constructor() {
		effect(
			() => {
				const { code } = this.customer() || {};

				if (!code) {
					this.areDevicesLoaded.set(false);
					return;
				}

				const devicesSub$ = this.devicesService.getDevices(code).subscribe(() => {
					this.areDevicesLoaded.set(true);
				});

				this.subscriptions.push(devicesSub$);
			},
			{ allowSignalWrites: true },
		);
	}

	ngOnDestroy(): void {
		this.subscriptions?.forEach((sub) => sub?.unsubscribe());
	}

	openDevice(row: DeviceRouter | DeviceAndroid) {
		this.setWorkOrder(row.workOrder);
		this.router.navigate([this.customer()?.code, 'devices', row.id]);
	}

	onActionSuccess(data?: { success: boolean }) {
		this.modalService.close();

		if (!data?.success) {
			return;
		}

		const devicesSub$ = this.devicesService.getDevices(this.customer()?.code as string).subscribe();

		this.subscriptions.push(devicesSub$);
	}

	async openActionModal(event: Event, row: DeviceAndroid, modal: ModalTypes) {
		event.preventDefault();
		event.stopPropagation();

		await this.modalService.open(modal, row, MODAL_CONFIG);
		this.modalService.afterClose(this.onActionSuccess.bind(this));
	}

	setWorkOrder(workOrder: number) {
		this.devicesService.setWorkOrder(workOrder);
	}
}
